import { IndeterminateCheckBox, RepeatOneSharp } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardAPI from "../api/dashboard";
import Chart from "react-apexcharts";
const Home = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (currentUser === null) {
      return navigate("/signin");
    }
  }, []);
  const [dataToShow, setDataToShow] = useState({});
  const [depositGraph, setDepositGraph] = useState();
  const [withdrawGraph, setWithdrawGraph] = useState([
    0, 0, 0, 0, 0, 21935.23368783, 0,
  ]);
  const [loading, setLoading] = useState(false);
  const bets = useSelector((state) => state.bets.bets);
  const total_bet_amount = useSelector((state) => state.bets.total_bet_amount);
  const total_players = useSelector((state) => state.players.total_players);

  const [state, setState] = useState({});
  const [newstate, setNewState] = useState({});
  useEffect(() => {
    DashboardAPI.getDashboardStat()
      .then((response) => {
        setLoading(true);
        setDataToShow(response?.data);
        setState({
          options: {
            title: {
              text: "Deposits in the past (7) days",
              align: "center",
              margin: 10,
              offsetX: 0,
              offsetY: 0,
              floating: false,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: undefined,
                color: "#818386",
              },
            },
            grid: {
              show: false,
              padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 10,
              },
            },
            dataLabels: {
              enabled: false,
            },
            chart: {
              toolbar: {
                show: false,
              },

            },
            colors: ["#ff9832"],
            // labels: ["Day1", "Day2", "Day3", "Day4 ", "Day5", "Day6", "Day7"],
            labels: response.data.last_7_days_deposits[1],
            yaxis: {
              labels: {
                formatter: function (value) {
                  return "$" + parseFloat(value).toFixed(4);
                },
              },
            },
          },
          series: [
            {
              name: "Deposits",
              // data: [0, 40, 44, 30, 49, 20, 61],

              data: response.data.last_7_days_deposits[0] || [
                0, 0, 0, 0, 0, 0, 0,
              ],
            },
          ],
        });

        setNewState({
          options: {
            grid: {
              show: false,
            },
            chart: {
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },

            title: {
              text: "Withdrawals in the past (7) days",
              align: "center",
              margin: 10,
              offsetX: 0,
              offsetY: 0,
              floating: false,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: undefined,
                color: "#818386",
              },
            },
            colors: ["#094ab3"],
            // labels: ["Day1", "Day2", "Day3", "Day4 ", "Day5", "Day6", "Day7"],
            labels: response.data.last_7_days_withdraw[1],
            yaxis: {
              labels: {
                formatter: function (value) {
                  return "$" + parseFloat(value).toFixed(4) ;
                },
              },
            },
          },
          series: [
            {
              name: "Withdrawals",
              // data: [0, 40, 4, 30, 49, 20, 61],
              data: response.data.last_7_days_withdraw[0] || [
                0, 0, 0, 0, 0, 0, 0,
              ],
            },
          ],
        });
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <div className="content">
      <div className="container-fluid pt-4 px-4">
        <div className="page-title">
          <h1>Dashboard</h1>
        </div>
        <div className="row g-4 justify-content-center">
          <div className="col-sm-6 col-xl-4">
            <div className="dashboard-summary">
              <div className="dashboard-summary-arrow">
                <p className="text">Registered Members</p>
                {dataToShow.reg_members_percentage >= 0 ? (
                  <>
                    <p className="arrow green">
                      <i className="fas fa-caret-up"></i>{" "}
                      {dataToShow.reg_members_percentage}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="arrow red">
                      <i className="fas fa-caret-down"></i>{" "}
                      {dataToShow.reg_members_percentage}
                    </p>
                  </>
                )}
              </div>
              <h1>{dataToShow.new_users}</h1>
              <p className="mb-0">
                Total Members : {dataToShow.all_registerd_users}
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-xl-4">
            <div className="dashboard-summary">
              <div className="dashboard-summary-arrow">
                <p className="text">Amount Deposited</p>
                {dataToShow.reg_members_percentage >= 0 ? (
                  <>
                    <p className="arrow green">
                      <i className="fas fa-caret-up"></i>{" "}
                      {dataToShow.amount_deposited_percentage}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="arrow red">
                      <i className="fas fa-caret-down"></i>{" "}
                      {dataToShow.amount_deposited_percentage}
                    </p>
                  </>
                )}
              </div>
              <h1>${parseFloat(dataToShow.total_deposits_today).toFixed(2)}</h1>
              <p className="mb-0">
                Total Deposits : $
                {parseFloat(dataToShow.total_deposits).toFixed(2)}
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-xl-4">
            <div className="dashboard-summary">
              <div className="dashboard-summary-arrow">
                <p className="text">Amount Withdrawn</p>
                {dataToShow.amount_withdrawn_percentage >= 0 ? (
                  <>
                    <p className="arrow green">
                      <i className="fas fa-caret-up"></i>{" "}
                      {dataToShow.amount_withdrawn_percentage}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="arrow red">
                      <i className="fas fa-caret-down"></i>{" "}
                      {dataToShow.amount_withdrawn_percentage}
                    </p>
                  </>
                )}
              </div>
              <h1>
                ${parseFloat(dataToShow.total_withdrawals_today).toFixed(2)}
              </h1>
              <p className="mb-0">
                Total Withdrawals : $
                {parseFloat(dataToShow.total_withdrawals).toFixed(2)}
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-xl-4">
            <div className="dashboard-summary">
              <div className="dashboard-summary-arrow">
                <p className="text">Active Users</p>
                <p className="arrow bullet">
                  <span></span>
                </p>
              </div>
              <h1>{dataToShow.active_users}</h1>
              <p className="mb-0">
                Total Members : {dataToShow.all_registerd_users}
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-xl-4">
            <div className="dashboard-summary">
              <div className="dashboard-summary-arrow">
                <p className="text">Active Bets</p>

                <p className="arrow bullet">
                  <span></span>
                </p>
              </div>
              <h1>${parseFloat(total_bet_amount).toFixed(2)}</h1>
              <p className="mb-0">Total Players : {total_players}</p>
              {/* <p className="mb-0">Bet Amount Last Month : $2,322,345</p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid px-4">
        <div className="row">
          <div className="col-md-12 pt-4">
            <div
              alt=""
              className="w-100"
              style={{ background: "#1a1b20", "border-radius": "20px", "padding": "15px 0 0 0" }}
            >
              <div className="chat">
                {loading && (
                  <Chart
                    options={state.options}
                    series={state.series}
                    type="bar"
                    width="100%"
                    height={400}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12 pt-4">
            <div
              className="w-100"
              style={{ background: "#1a1b20", "border-radius": "20px", "padding": "15px 0 0 0" }}
            >
              <div className="chat">
                {loading && (
                  <Chart
                    options={newstate.options}
                    series={newstate.series}
                    type="bar"
                    width="100%"
                    height={400}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4 pb-4 px-4">
        <div className="text-center rounded p-4 table-bg">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h6 className="mb-0 active-bets">Active Bets</h6>
            <a className="view-all" href="Javascript:;">
              View All Bets
            </a>
          </div>
          <hr className="dropdown-divider" />
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover mb-0 text-center table-style">
              <thead>
                <tr>
                  <th scope="col">Bet ID</th>
                  <th scope="col">Cashout</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Profit</th>
                  <th scope="col">User</th>
                  <th scope="col">Network</th>
                </tr>
              </thead>
              <tbody>
                {bets.map((obj) => {
                  return (
                    <tr>
                      <td>{obj.bet_id}</td>
                      <td>{obj.cash_out}</td>
                      <td> {obj.amount}</td>
                      <td> {obj.profit}</td>
                      <td> {obj.user_id}</td>
                      <td> {obj.network_id}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <br/>
      </div>
    </div>
  );
};

export default Home;

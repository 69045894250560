import axiosClient from ".";

class BetRoundsAPI {
  static getBetRound(page, filter, filterId, idPredicate, filterMultiplier, multiplierPredicate, filterActive, activePredicate, startDate, endDate) {
    return axiosClient.get(`/super_admin/api/bet_rounds.json?page=${page}/${filter && `&q[id_${idPredicate}]=${filterId}&q[is_active_${activePredicate}]=${filterActive}&q[multiplier_no_${multiplierPredicate}]=${filterMultiplier}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}`
      }`);
  }
  static viewBetRound(data) {
    return axiosClient.get(`/super_admin/api/bet_rounds/${data.id}.json`);
  }
}
export default BetRoundsAPI;

// BetRoundsAPI.getBetRound({
//   id:id
// }).then.
